.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1p solid #aaa;
  margin: 0 0 10px;
  padding: 0;
  max-width: 1394.56px;
}

.react-tabs__tab {
  /* outline: 1px solid black; */
  flex-shrink: 1; /* allow tab el to shrink */
  flex-grow: 0; /* Prevent items from growing */
  flex-basis: auto;
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 6px;
  cursor: pointer;
  opacity: 0.5;
  /* Prevent text wrapping */
  white-space: nowrap;
  /* Hide any overflowed text */
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: #1958b8;
  padding: 6px 12px;
  border-radius: 5px 5px 0 0;
  opacity: 1;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
