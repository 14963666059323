html {
  font-size: 14px;
}

body {
  font-family: 'Exo', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.input {
  border: none;
  outline: none;
}

.input::placeholder {
  opacity: 0.4;
}

.button-container {
  display: flex;
}

.option-button {
  background-color: #1E1E1E;
  border: none;
  cursor: pointer;
  color: white;
  padding: 8px;
}

.option-button.active {
  background-color: black;
  color: white
}
