.App {
  /* text-align: center; */
}

.loginImage {
  width: 100%;
  height: auto;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.activeClassName:first-of-type {
  color: #1958b8;
  background-color: #1958b8;
  text-decoration: none;
  text-align: center;
  /* '& div': {
    padding: 15,
    backgroundColor: 'red',
    color: 'pink',
  }, */
  display: flex;
  justify-content: flex-start;
}

.nonActiveClassName:first-of-type {
  color: black;
  text-decoration: none;
  background-color: green;
  /* '& div': {
    background-color: green;
    color: 'indigo',
  }, */
  display: flex;
  justify-content: flex-start;
}

/* .loginImage {
  background-image: url("/images/laptop.svg");
  background-size: cover;
  box-sizing: border-box;
  min-height: 100vh;
} */

.overlay {
  opacity: 0;
  background-color: darkslateblue;
}

.accountImageContainer:hover .overlay {
  opacity: 1;
  background-color: aquamarine;
}

.mainBlock {
  width: calc(100vw - 19rem);
  left: 12rem;
  /* right: 13rem; */
  position: relative;
  /* background-color: hotpink; */
}

.profileImageContainer {
  display: inline-flex;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1958b8;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.profileImageContainer img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.iconButton {
  position: absolute;
  top: 4.9rem;
  /* right: '2.2rem', */
  left: 3.5rem;
  background-color: #fff;
}

.profileImage {
  /* width: 100%; */
  height: 7.5rem;
  position: relative;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .mainBlock {
    /* width: calc(100vw - 15rem); */
    /* width: 100vw; */
    width: 100%;
    left: 0rem;
    position: relative;
    margin-top: 2rem;
    /* background-color: blueviolet; */
  }

  .profileImageContainer {
    display: flex;
    justify-content: center;
    position: relative;
  }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .mainBlock {
    width: calc(100vw - 16rem);
    left: 12rem;
    /* right: 13rem; */
    position: relative;
    /* background-color: blueviolet; */
  }
}
.react-resizable-handle {
  border: none;
  z-index: 10;
  position: absolute;
  background-image: none !important;
}
.react-resizable-handle:hover {
  border-right: 2px solid rgba(25, 88, 184, 0.5) !important; /* Change border color to blue */
}
/* .react-resizable-handle.w {
  top: 50%;
  left: 10px;
  margin-top: -10px;
  cursor: w-resize;
  border-left: 5px solid #000; 
} */
.react-resizable .react-resizable-handle-e {
  transform: none;
  top: 0%;
  height: 100%;
  width: 5px !important;
  right: 0px;
  color: green;
  margin-top: -10px;
  z-index: 10;
  cursor: e-resize;
}
.react-resizable .react-resizable-handle-n {
  background-color: goldenrod;
  transform: rotate(90deg) !important;
  bottom: 0 !important;
  /* height: 1000px; */
  width: 5px !important;
  right: 0px;
  color: green;
  margin-top: 0;
  z-index: 10;
  cursor: ns-resize;
}
